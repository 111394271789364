import React, { Component } from "react";
import { Link } from "gatsby";
import logo from "../img/crop_bh.png";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: ""
    };
  }

  toggleBurger = e => {
    // is - active
    this.setState({
      active: this.state.active ? false : "is-active"
    });
    console.log(this.state);
  };
  render() {
    console.log({ Link });

    return (
      <div
        className="navbar is-transparent"
        aria-label="main navigation"
        style={{
          backgroundColor: "transparent"
          // backgroundImage: "none"
        }}
      >
        <div className="navbar-brand is-transparent">
          <Link to="/" className="navbar-item">
            <img src={logo} style={{ maxHeight: "50px" }} />
          </Link>
          <div
            className={` navbar-burger ${this.state.active}`}
            data-target="navMenu"
            onClick={this.toggleBurger}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={`navbar-menu ${this.state.active}`} id="navMenu">
          <div className="navbar-start">
            <Link
              className="navbar-item"
              to="/bitters"
              style={{ fontSize: "1.5em", textDecoration: "none" }}
              activeStyle={{ color: "black", fontWeight: "bold" }}
            >
              Bitters
            </Link>
            <Link
              className="navbar-item"
              to="/recipes"
              style={{ fontSize: "1.5em" }}
              activeStyle={{ color: "black", fontWeight: "bold" }}
            >
              Recipe Vault
            </Link>
            <Link
              className="navbar-item"
              to="/customcocktails"
              style={{ fontSize: "1.5em" }}
              activeStyle={{ color: "black", fontWeight: "bold" }}
            >
              Custom Cocktails
            </Link>
            <Link
              className="navbar-item"
              to="/aboutus"
              style={{ fontSize: "1.5em" }}
              activeStyle={{ color: "black", fontWeight: "bold" }}
            >
              About Us
            </Link>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <Link
                    style={{ backgroundColor: "#C0B71A" }}
                    className="button  "
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
